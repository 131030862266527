/**
 * @jsxFrag
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { forwardRef, Fragment, useCallback, useRef } from 'react';

import { cssMap, cx, jsx } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AvatarContext, type AvatarContextProps } from '@atlaskit/avatar';
import mergeRefs from '@atlaskit/ds-lib/merge-refs';
import { fg } from '@atlaskit/platform-feature-flags';
import { Anchor, Pressable, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, {
	TooltipPrimitive,
	type TooltipPrimitiveProps,
	type TooltipProps,
} from '@atlaskit/tooltip';

import { InteractionConsumer, InteractionSurface } from '../../components/interaction-surface';

import { expandableMenuItemIndentation } from './constants';
import { useLevel } from './expandable-menu-item/expandable-menu-item-context';
import {
	useFlyoutMenuOpen,
	useSetFlyoutMenuOpen,
} from './flyout-menu-item/flyout-menu-item-context';
import { COLLAPSE_ELEM_BEFORE } from './menu-item-signals';
import type { MenuItemCommonProps } from './types';

const tooltipStyles = cssMap({
	root: {
		// Unique styles for our tooltip
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		// matching the height of standard items
		minHeight: '32px',
		// increased max width from standard tooltip
		maxWidth: '420px',

		// Mostly copied styles from `tooltip/src/tooltip-container.tsx`
		// Could likely use `TooltipContainer` from Tooltip and override styles,
		// but that could lead to some unexpected results as Tooltip is styled
		// using emotion.
		boxSizing: 'border-box',
		paddingBlockStart: token('space.025'),
		paddingInlineEnd: token('space.075'),
		paddingBlockEnd: token('space.025'),
		paddingInlineStart: token('space.075'),
		backgroundColor: token('color.background.neutral.bold'),
		borderRadius: token('border.radius'),
		color: token('color.text.inverse'),
		font: token('font.body.UNSAFE_small'),
		insetBlockStart: token('space.0'),
		insetInlineStart: token('space.0'),
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
	},
});

const MenuItemTooltip = forwardRef<HTMLDivElement, TooltipPrimitiveProps>(function MenuItemTooltip(
	{ children, className, ...rest },
	ref,
) {
	return (
		<TooltipPrimitive
			{...rest}
			// Manually passing on `className` so it gets merged correctly in the build output.
			// The passed classname is mostly used for integration testing (`.Tooltip`)
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-classname-prop
			className={className}
			// "css" does not "exist" - it gets transformed into "className" by compiled
			css={tooltipStyles.root}
			ref={ref}
		>
			{children}
		</TooltipPrimitive>
	);
});

function isTextClamped(element: HTMLElement): boolean {
	// Checking for vertical height rather than horizontal height.
	// When text is "clamped", it's technically being clamped vertically! 🤯
	return element.scrollHeight > element.clientHeight;
}

const defaultAvatarValues: AvatarContextProps = {
	size: 'small',
};

const elemAfterDisplayVar = '--elem-after-display';
const actionsOnHoverDisplayVar = '--actions-on-hover-display';
const notchColorVar = '--notch-color';

/**
 * ## 🤹 `position:relative`
 *
 * We need `position:relative` on an element that takes
 * up the full size of the interactive element so we
 * can correctly use `position:absolute` to place:
 * 1. the notch for links
 * 2. a child of button / anchor to stretch it out to
 *    increase it's pressable area.
 *
 * ⛔️ We cannot add `position:relative` _only_ on the
 * button / anchor as that will cause sibling elements
 * to be rendered under the button / anchor when setting
 * a background color on the button / anchor.
 *
 * 📖 Note: `position:relative` elements are painted after
 * elements with `position:static` (the default)
 * https://drafts.csswg.org/css-position-4/#painting-order
 *
 * ⛔️ We cannot add `position:relative` to the container
 * element, as then the `:focus` ring styles on the
 * button / anchor can be cut off by the next sibling if it has
 * has a background color set (eg when selected)
 *
 * ✅ Add `position:relative` to all first level descendants
 * of the container element so that we don't impact DOM ordered
 * paint ordering within the item and the button / anchor focus
 * ring can still bleed over siblings
 *
 * 📖 We could use `> * { position: relative; }` on the container,
 * but that would violate our styling standard.
 */
const topLevelSiblingStyles = cssMap({
	root: {
		position: 'relative',
	},
});

/**
 * All slots on the menu item (eg `elemBefore`) are rendered as siblings
 * of our main button / anchor element and they are visually placed on
 * top of the main button / anchor.
 *
 * 📖 This is done so that we don't nest interactive elements in our markup.
 *
 * ✅ This is great when element in the slot is an interactive element
 * as we don't want the main menu item button / anchor to be triggered
 * when interacting with the element in the slot.
 *
 * ⛔️ When the element in the slot is static content (eg an `<Icon>`) it will
 * prevent the main button / anchor (that is visually behind the element in
 * the slot) from being clicked. The element in the slot is a sibling of our
 * main button / anchor (not a child of it) so clicking on the element in the
 * slot will not bubble up to the button / anchor.
 *
 * 🚀 We set `pointer-events:none` on a slot if it does not contain and interactive
 * element so that static content in a slot does not prevent clicking on the main
 * button / anchor.
 */
const onTopOfButtonOrAnchorStyles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:not(:has(button,a))': {
			pointerEvents: 'none',
		},
	},
});

/**
 * This selector is used to apply hover styling on the menu item if it contains an open nested popup.
 * It's looking for a popup trigger with `aria-hasexpanded` and `aria-haspopup` attributes. The
 * reason for targeting the _trigger_ instead of the popup itself, is the popup might be rendered
 * outside the menu item, e.g. if rendered in a portal.
 *
 * An alternative solution might involve wrapping it with a popup context and listening to the popup
 * events through it (and applying the hover style when the popup is open). Exploring this has been
 * captured in [BLU-3354](https://jplat.atlassian.net/browse/BLU-3354).
 */
const nestedOpenPopupCSSSelector = '&:has([aria-expanded="true"][aria-haspopup="true"])';

const containerStyles = cssMap({
	root: {
		boxSizing: 'border-box',
		display: 'grid',
		gridTemplateColumns: 'minmax(0, auto) 1fr repeat(3, minmax(0, auto))',
		/**
		 * A min-width is set to ensure that the menu items do not completely collapse when deeply nested.
		 * Otherwise, the menu items progressively shrink as they get into deeper levels of expandable menu items, until they
		 * are unusable.
		 */
		minWidth: '72px',
		height: '32px',
		alignItems: 'center',
		userSelect: 'none',
		borderRadius: token('border.radius'),
		color: token('color.text.subtle'),
		[actionsOnHoverDisplayVar]: 'none',
		[notchColorVar]: 'transparent',
		[elemAfterDisplayVar]: 'flex',
		// Applying :hover styles on the container rather than on
		// just the button / anchor so that we will still trigger the
		// :hover styles when over action buttons
		'&:hover': {
			backgroundColor: token('elevation.surface.hovered'),
		},
		'&:hover, &:focus-within': {
			[actionsOnHoverDisplayVar]: 'flex',
		},
		// If there is a nested open popup, we want to apply hover styling, and display the `actionsOnHover` slot.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
		[nestedOpenPopupCSSSelector]: {
			backgroundColor: token('elevation.surface.hovered'),
			[actionsOnHoverDisplayVar]: 'flex',
		},
	},
	removeElemAfter: {
		[elemAfterDisplayVar]: 'none',
	},
	showHoverActions: {
		[actionsOnHoverDisplayVar]: 'flex',
	},
	removeElemAfterOnHover: {
		// On hover of the menu item, remove the elemAfter
		'&:hover, &:focus-within': {
			[elemAfterDisplayVar]: 'none',
		},
	},
	removeElemAfterOnHoverOrOpenNestedPopup: {
		// On hover of the menu item, remove the elemAfter
		'&:hover, &:focus-within': {
			[elemAfterDisplayVar]: 'none',
		},
		// If there is a nested open popup, and both `actionsOnHover` and `elemAfter` exist, we want to hide the `elemAfter`.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
		[nestedOpenPopupCSSSelector]: {
			[elemAfterDisplayVar]: 'none',
		},
	},
	selected: {
		backgroundColor: token('color.background.selected'),
		color: token('color.text.selected'),
		[notchColorVar]: token('color.background.selected.bold'),
		'&:hover': {
			color: token('color.text.selected'),
			backgroundColor: token('color.background.selected.hovered'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
		[nestedOpenPopupCSSSelector]: {
			backgroundColor: token('color.background.selected.hovered'),
		},
	},
	pressed: {
		// TODO: clean up `containerStyles.pressed` with fg('platform_dst_menu_item_actions_on_hover_refactor').
		backgroundColor: token('elevation.surface.pressed'),
	},
	hasDescription: {
		/* Standard 32px + another 16px for the description */
		height: '48px',
	},
});

const buttonOrAnchorStyles = cssMap({
	// This button / anchor is positioned to produce the visual appearance of nested
	// buttons whilst the elements are actually siblings in the DOM structure.
	root: {
		display: 'grid',
		// Extend the button to the full width of container
		gridColumn: '1 / -1',
		// Each grid item is placed on the same row and stacks on top of each other.
		gridRow: '1',
		gridTemplateColumns: 'subgrid',
		height: '100%',
		paddingBlockStart: token('space.050'),
		paddingInlineEnd: token('space.050'),
		paddingBlockEnd: token('space.050'),
		paddingInlineStart: token('space.050'),
		backgroundColor: 'transparent',
		borderRadius: token('border.radius'),
		color: token('color.text.subtle'),
		alignItems: 'center',
		textAlign: 'start',
		// :active styles are applied on the button / anchor rather
		// than on the container so that pressing on actions does not
		// trigger the :active styles on the whole element.
		'&:active': {
			backgroundColor: token('elevation.surface.pressed'),
		},
	},
	selected: {
		color: token('color.text.selected'),
		'&:active': {
			backgroundColor: token('color.background.selected.pressed'),
		},
	},
});

const extendButtonOrAnchorStyles = cssMap({
	root: {
		position: 'absolute',
		inset: token('space.0'),
	},
});

const notchStyles = cssMap({
	root: {
		position: 'absolute',
		insetBlockStart: '50%',
		insetInlineStart: token('space.0'),
		width: '2px',
		height: '12px',
		transform: 'translateY(-50%)',
		backgroundColor: `var(${notchColorVar})`,
	},
});

const actionStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.050'),
		gridRow: '1',
		gridColumn: '5',
		paddingInlineEnd: token('space.050'),
		// Hiding overflowing slot content to prevent content from adjacent slots overlapping when the menu item is constrained to a narrow width.
		overflow: 'hidden',
		'&:focus-within': {
			// To prevent the focus ring from being clipped, we are allowing content to overflow while focus is within the slot.
			overflow: 'initial',
		},
	},
});

const actionsOnHoverStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.050'),
		paddingInlineEnd: token('space.050'),
		// Hiding overflowing slot content to prevent content from adjacent slots overlapping when the menu item is constrained to a narrow width.
		overflow: 'hidden',
		'&:focus-within': {
			// To prevent the focus ring from being clipped, we are allowing content to overflow while focus is within the slot.
			overflow: 'initial',
		},
	},
	gridPosition: {
		gridRow: '1',
		gridColumn: '3',
	},
	removeInteractionSurfaceRefactor: {
		display: `var(${actionsOnHoverDisplayVar})`,
		gridRow: '1',
		gridColumn: '3',
		alignItems: 'center',
		gap: token('space.050'),
		paddingInlineEnd: token('space.050'),
		// Hiding overflowing slot content to prevent content from adjacent slots overlapping when the menu item is constrained to a narrow width.
		overflow: 'hidden',
		'&:focus-within': {
			// To prevent the focus ring from being clipped, we are allowing content to overflow while focus is within the slot.
			overflow: 'initial',
		},
	},
});

const textStyles = cssMap({
	root: {
		gridColumn: '2',
		paddingInlineEnd: token('space.050'),
		paddingInlineStart: token('space.050'),
		display: 'flex',
		flexDirection: 'column',
		gap: token('space.025'),
		// Allowing at least one character to be seen at all times, even when the menu item has been shrunk down
		minWidth: '1ch',
		// Hiding overflowing slot content to prevent content from adjacent slots overlapping when the menu item is constrained to a narrow width.
		overflow: 'hidden',
		'&:focus-within': {
			// To prevent the focus ring from being clipped, we are allowing content to overflow while focus is within the slot.
			overflow: 'initial',
		},
	},
	noElemBeforeIndent: {
		paddingInlineStart: token('space.075'),
	},
});

const elemBeforeStyles = cssMap({
	root: {
		gridColumn: '1',
		gridRow: '1',
		display: 'flex',
		flexShrink: 0,
		width: '24px',
		height: '24px',
		alignItems: 'center',
		justifyContent: 'center',
		paddingInlineStart: token('space.050'),
		// Box sizing must be explicit because we set a size AND padding on the same axis
		// Otherwise the resulting size can be inconsistent depending on the global reset used
		boxSizing: 'content-box',
		// Hiding overflowing slot content to prevent content from adjacent slots overlapping when the menu item is constrained to a narrow width.
		overflow: 'hidden',
		'&:focus-within': {
			// To prevent the focus ring from being clipped, we are allowing content to overflow while focus is within the slot.
			overflow: 'initial',
		},
	},
});

const elemAfterStyles = cssMap({
	root: {
		display: `var(${elemAfterDisplayVar})`,
		gridColumn: '4',
		gridRow: '1',
		flexShrink: 0,
		height: '24px',
		alignItems: 'center',
		paddingInlineEnd: token('space.050'),
		// Hiding overflowing slot content to prevent content from adjacent slots overlapping when the menu item is constrained to a narrow width.
		overflow: 'hidden',
		'&:focus-within': {
			// To prevent the focus ring from being clipped, we are allowing content to overflow while focus is within the slot.
			overflow: 'initial',
		},
	},
});

type MenuItemBaseProps = MenuItemCommonProps & {
	href?: string | Record<string, any>;
	target?: HTMLAnchorElement['target'];
	isDisabled?: boolean;
	isSelected?: boolean;
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention, @repo/internal/react/consistent-props-definitions
	ariaControls?: string;
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention, @repo/internal/react/consistent-props-definitions
	ariaExpanded?: boolean;
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention, @repo/internal/react/consistent-props-definitions
	ariaHasPopup?: boolean | 'dialog';
};

/**
 * __MenuItemBase__
 *
 * The base menu item component used to compose MenuButtonItem and MenuLinkItem.
 */
export const MenuItemBase = forwardRef<HTMLAnchorElement | HTMLButtonElement, MenuItemBaseProps>(
	(
		{
			testId,
			actions,
			actionsOnHover,
			children,
			description,
			elemAfter,
			elemBefore,
			href,
			target,
			isDisabled,
			isSelected,
			onClick,
			ariaControls,
			ariaExpanded,
			ariaHasPopup,
			interactionName,
			isContentTooltipDisabled,
			visualContentRef,
		},
		forwardedRef,
	) => {
		const level = useLevel();
		const setFlyoutMenuOpen = useSetFlyoutMenuOpen();
		const isFlyoutMenuOpen = useFlyoutMenuOpen();
		const isLink = typeof href !== 'undefined';
		const labelRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(null);
		const descriptionRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(null);
		const tooltipOnClick = useRef<React.MouseEventHandler<HTMLElement> | null>(null);

		const handleClick = useCallback(
			(
				event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				onClick?.(event, analyticsEvent);
				// Toggle flyout menu open state when inside a flyout context provider
				setFlyoutMenuOpen(!isFlyoutMenuOpen);

				tooltipOnClick.current?.(event);
			},
			[onClick, setFlyoutMenuOpen, isFlyoutMenuOpen],
		);

		/**
		 * Show the tooltip if _either_ label or description is truncated
		 */
		const canTooltipAppear = useCallback((): boolean => {
			if (isContentTooltipDisabled) {
				return false;
			}

			// Show tooltip if _either_ label or description are clamped

			const label = labelRef.current;
			const description = descriptionRef.current;

			if (label && isTextClamped(label)) {
				return true;
			}

			return Boolean(description && isTextClamped(description));
		}, [isContentTooltipDisabled]);

		// By default provide the spacing for `elemBefore` to have good
		// vertical alignment of labels and to give clear indentation between levels
		// in the side navigation (even when items don't use elemBefore).
		const showElemBefore = elemBefore !== COLLAPSE_ELEM_BEFORE;

		const interactiveElemContent = (
			<>
				<div
					css={extendButtonOrAnchorStyles.root}
					// This extends the clickable area of nested menu items to the width
					// of the root level menu items, while being visually indented.
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					style={{ insetInlineStart: `calc(-1 * ${level} * ${expandableMenuItemIndentation})` }}
					aria-hidden="true"
				/>
				<div css={[textStyles.root, !showElemBefore && textStyles.noElemBeforeIndent]}>
					<Text
						weight="medium"
						maxLines={1}
						color={isSelected ? 'color.text.selected' : 'color.text.subtle'}
						ref={labelRef}
					>
						{children}
					</Text>
					{description && (
						<Text color="color.text.subtle" size="small" maxLines={1} ref={descriptionRef}>
							{description}
						</Text>
					)}
				</div>
			</>
		);

		/**
		 *  If the [expandable] menu item is expanded, show hover actions even when *not* hovered.
		 *
		 * Note: we also remove the `elemAfter` when showing `actionsOnHover`
		 *
		 * This is not applied if the menu item itself is a popup trigger (based on `aria-haspopup`) - this is for flyout menu items.
		 * This `!ariaHasPopup` check is not strictly needed though, as flyout menu items don't have the `actionsOnHover` slot.
		 */
		const showHoverActionsWhenNotHovered = fg('platform_dst_menu_item_actions_on_hover_refactor')
			? Boolean(ariaExpanded && actionsOnHover)
			: Boolean(!ariaHasPopup && ariaExpanded && actionsOnHover);

		const Wrapper = fg('platform_dst_menu_item_actions_on_hover_refactor')
			? Fragment
			: InteractionSurface;

		return (
			<Wrapper>
				<AvatarContext.Provider value={defaultAvatarValues}>
					<div
						ref={visualContentRef}
						css={[
							containerStyles.root,
							isSelected && containerStyles.selected,
							description && containerStyles.hasDescription,
							// We don't actually need to apply these `containerStyles.pressed` styles. Remove them with fg('platform_dst_menu_item_actions_on_hover_refactor').
							// I believe they were intended to apply `pressed` styles to flyout menu item triggers when open (expanded).
							// However, in practice these styles aren't actually being used - the nestedOpenPopupCSSSelector styles overrides
							// these, as they are higher specificity.
							// These styles aren't accurate either - we should be displaying hover styles when the flyout menu item is open.
							ariaHasPopup &&
								ariaExpanded &&
								!fg('platform_dst_menu_item_actions_on_hover_refactor') &&
								containerStyles.pressed,
							// If the menu item has actionsOnHover and is expanded, show hover actions even when not hovered
							showHoverActionsWhenNotHovered && containerStyles.showHoverActions,
							// If the menu item has both actionsOnHover and elemAfter and is expanded, remove elemAfter to make more space for actionsOnHover.
							showHoverActionsWhenNotHovered && elemAfter && containerStyles.removeElemAfter,
							// If the menu item has both actionsOnHover and elemAfter, remove elemAfter on hover to make more space for actionsOnHover.
							actionsOnHover &&
								elemAfter &&
								!fg('platform_dst_menu_item_actions_on_hover_refactor') &&
								containerStyles.removeElemAfterOnHover,
							// If the menu item has both actionsOnHover and elemAfter, remove elemAfter to make more space for actionsOnHover, when:
							// - menu item is hovered, or
							// - there is an open nested popup (as we apply hover styles when there is an open nested popup)
							actionsOnHover &&
								elemAfter &&
								fg('platform_dst_menu_item_actions_on_hover_refactor') &&
								containerStyles.removeElemAfterOnHoverOrOpenNestedPopup,
						]}
						data-testid={testId ? `${testId}-container` : undefined}
						data-selected={isSelected}
					>
						<Tooltip
							content={() => (
								<>
									<div>{children}</div>
									{description ? <div>{description}</div> : null}
								</>
							)}
							position="right"
							// NOTE: Types in React 18 have changed and `forwardRef(() => <TooltipPrimitive>)` no longer appears to match 100%
							component={MenuItemTooltip as TooltipProps['component']}
							ignoreTooltipPointerEvents
							hideTooltipOnMouseDown
							// We don't need a duplicate hidden element containing tooltip content
							// as the content of the tooltip matches what is rendered for the menu item.
							isScreenReaderAnnouncementDisabled
							canAppear={canTooltipAppear}
						>
							{(tooltipProps) => {
								// Putting the tooltip onClick into a ref.
								// This way we don't need to create a new `onClick` function on each
								// render (as we would need to merge `tooltipProps.onClick` and our `handleClick`)
								tooltipOnClick.current = tooltipProps.onClick;

								const sharedProps = {
									...tooltipProps,
									'aria-controls': ariaControls,
									'aria-haspopup': ariaHasPopup,
									ref: mergeRefs([forwardedRef, tooltipProps.ref]),
									testId,
									interactionName,
									onClick: handleClick,
								};

								return isLink ? (
									<Anchor
										{...sharedProps}
										xcss={cx(
											buttonOrAnchorStyles.root,
											topLevelSiblingStyles.root,
											isSelected && buttonOrAnchorStyles.selected,
										)}
										// Needed to override Anchor style due to a compiled/emotion conflict
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
										style={{ textDecoration: 'none' }}
										aria-current={isSelected ? 'page' : undefined}
										href={href}
										target={target}
									>
										<div css={notchStyles.root} aria-hidden="true" />
										{interactiveElemContent}
									</Anchor>
								) : (
									<Pressable
										{...sharedProps}
										xcss={cx(
											buttonOrAnchorStyles.root,
											topLevelSiblingStyles.root,
											isSelected && buttonOrAnchorStyles.selected,
										)}
										aria-expanded={ariaExpanded}
										isDisabled={isDisabled}
									>
										{interactiveElemContent}
									</Pressable>
								);
							}}
						</Tooltip>
						{showElemBefore && (
							<div
								css={[
									elemBeforeStyles.root,
									topLevelSiblingStyles.root,
									onTopOfButtonOrAnchorStyles.root,
								]}
							>
								{elemBefore}
							</div>
						)}
						{actionsOnHover &&
							(fg('platform_dst_menu_item_actions_on_hover_refactor') ? (
								<div css={actionsOnHoverStyles.removeInteractionSurfaceRefactor}>
									{actionsOnHover}
								</div>
							) : (
								<InteractionConsumer
									xcss={actionsOnHoverStyles.gridPosition}
									// If the menu item is expanded, show hover actions even when not hovered
									behavior={showHoverActionsWhenNotHovered ? 'alwaysShown' : 'removeOnLeave'}
								>
									<div css={actionsOnHoverStyles.root}>{actionsOnHover}</div>
								</InteractionConsumer>
							))}
						{elemAfter && (
							<div
								css={[
									elemAfterStyles.root,
									topLevelSiblingStyles.root,
									onTopOfButtonOrAnchorStyles.root,
								]}
							>
								{elemAfter}
							</div>
						)}
						{actions && (
							<div
								css={[
									actionStyles.root,
									topLevelSiblingStyles.root,
									onTopOfButtonOrAnchorStyles.root,
								]}
							>
								{actions}
							</div>
						)}
					</div>
				</AvatarContext.Provider>
			</Wrapper>
		);
	},
);
